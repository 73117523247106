// 专业聚焦
export default [
  {
    path: "/focus", //专业聚焦
    component: () => import("@/views/ProfessionalFocus/focus/focusPage"),
    meta: {
      title: "麦默通 - 专业聚焦",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/college", //微创学院
    component: () => import("@/views/ProfessionalFocus/college/collegePage"),
    meta: {
      title: "麦默通 - 微创学院",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/guide", //文献指南
    component: () =>
      import("@/views/ProfessionalFocus/college/children/guidePage.vue"),
    meta: {
      title: "麦默通 - 文献指南",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/caseStudy", //案例研究
    component: () =>
      import("@/views/ProfessionalFocus/college/children/caseStudy.vue"),
    meta: {
      title: "麦默通 - 案例研究",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/healthCare", //健康关爱
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/healthCare.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/healthtext1", //健康关爱1
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/children/healthText1.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/healthtext2", //健康关爱2
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/children/healthText2.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/healthtext3", //健康关爱3
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/children/healthText3.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/healthtext4", //健康关爱4
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/children/healthText4.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/healthtext5", //健康关爱5
    component: () =>
      import("@/views/ProfessionalFocus/healthCare/children/healthText5.vue"),
    meta: {
      title: "麦默通 - 健康关爱",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/activity", //专业活动
    component: () =>
      import("@/views/ProfessionalFocus/college/activityPage.vue"),
    meta: {
      title: "麦默通 - 专业活动",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/activity1", //活动一
    name: "activity1",
    component: () => import("@/components/activity/activity1Page.vue"),
    meta: {
      title: "麦默通 - 麦默通真空辅助乳腺微创活检优秀视频评选大赛",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/activity2", //活动2
    name: "activity2",
    component: () => import("@/components/activity/activity2Page.vue"),
    meta: {
      title: "麦默通 - 2023麦默通学术沙龙",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/activity3", //活动3
    name: "activity3",
    component: () => import("@/components/activity/activity3Page.vue"),
    meta: {
      title: "麦默通 - 麦默通妇幼系统学术活动",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/operation", //手术应用
    name: "operation",
    component: () =>
      import("@/views/ProfessionalFocus/college/operationPage.vue"),
    meta: {
      title: "麦默通 - 手术应用",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  // 文献指南文章
  {
    path: "/guideText1", //文章1
    component: () => import("@/components/guide/guideText1.vue"),
    meta: {
      title: "麦默通 - 文献",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  // 2024文献指南文章
  {
    path: "/G20240802",
    component: () => import("@/components/guide/2024/G-20240802.vue"),
    meta: {
      title: "麦默通 - 文献",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/G20240329",
    component: () => import("@/components/guide/2024/G-20240329.vue"),
    meta: {
      title: "麦默通 - 文献",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/G20240719",
    component: () => import("@/components/guide/2024/G-20240719.vue"),
    meta: {
      title: "麦默通 - 文献",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
  {
    path: "/G20240425",
    component: () => import("@/components/guide/2024/G-20240425.vue"),
    meta: {
      title: "麦默通 - 文献",
      footTitle: "如果您需要了解产品或解决方案",
    },
  },
];
