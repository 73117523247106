export default [
  {
    path: "/scene",
    component: () => import("@/views/scene/scenePage.vue"),
    meta: {
      title: "麦默通 - 应用场景",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/ultrasonic", //超声
    component: () => import("@/views/scene/children/ultrasonicPage"),
    meta: {
      title: "麦默通 - 超声引导下的乳腺活检",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/molyb", //钼靶
    component: () => import("@/views/scene/children/molybdenumPage"),
    meta: {
      title: "麦默通 - 三维立体定位下的乳腺活检",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/resonance", //磁共振
    component: () => import("@/views/scene/children/resonancePage"),
    meta: {
      title: "麦默通 - 核磁共振引导下的乳腺活检",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
  {
    path: "/detection", //射线探测
    component: () => import("@/views/scene/children/detectionPage"),
    meta: {
      title: "麦默通 - 伽马射线探测装置",
      footTitle: "如果您需要了解产品和解决方案的更多信息",
    },
  },
];
