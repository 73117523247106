import Vue from "vue";
import VueRouter from "vue-router";
import aboutUs from "./aboutUs.js";
import recruit from "./recruit.js";
import product from "./product.js";
import ProfessionalFocus from "./ProfessionalFocus.js";
import scene from "./scene.js";
import landingPage from "./landingPage.js";

Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("@/views/home/homePage.vue"),
    meta: { title: "麦默通中国官网" },
  },
  {
    path: "/aboutUs",
    component: () => import("@/views/aboutUs/aboutUs.vue"),
    meta: {
      title: "麦默通 - 关于我们",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },
  {
    path: "/contactUs",
    component: () => import("@/views/contactUs/contactUs.vue"),
    meta: { title: "麦默通 - 联系我们" },
  },
  {
    path: "/colorPage",
    component: () => import("@/components/colorPage"),
    meta: { title: "麦默通 - 产品彩页" },
  },

  {
    path: "/HelloWorld",
    component: () => import("@/components/HelloWorld"),
    meta: { title: "麦默通 - 你好世界" },
  },
  ...aboutUs,
  ...recruit,
  ...product,
  ...ProfessionalFocus,
  ...scene,
  ...landingPage,
];
let router = new VueRouter({
  mode: "hash",
  routes,
});
// 路由测试
// let router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  from;
  if (process.env.NODE_ENV === "production") {
    if (window._hmt) {
      if (to.path) {
        window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
        console.log(window._hmt.push);
      }
    }
  }

  next();
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  to, from, next;
});

export default router;
