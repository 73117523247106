export default [
  {
    path: "/lixuan-lp",
    component: () => import("@/views/landingPage/LiXuan-lp.vue"),
    meta: {
      title: "麦默通 - 利旋™",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
  {
    path: "/ruiyan-lp",
    component: () => import("@/views/landingPage/ruiyan-lp.vue"),
    meta: {
      title: "麦默通 - revolve 锐旋®",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
  {
    path: "/ruixuan-lp",
    component: () => import("@/views/landingPage/ruixuan-lp.vue"),
    meta: {
      title: "麦默通 - revolve™ 锐旋®",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
];
