<template>
  <a-locale-provider :locale="zh_CN">
    <div id="app">
      <NavPageVue class="navPage"></NavPageVue>

      <router-view />

      <footPage />
      <GoTopVue />
    </div>
  </a-locale-provider>
</template>


<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};

import NavPageVue from "./components/NavPage.vue";
import footPage from "./components/footPage.vue";
import GoTopVue from "./components/GoTop.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
// import "moment/locale/zh-cn";
export default {
  name: "App",
  data() {
    return {
      zh_CN,
    };
  },
  components: {
    NavPageVue,
    footPage,
    GoTopVue,
  },
  watch: {
    // $route(to, from) {
    // },
  },
};
</script>


<style lang="less">
@import "./common/base.css";
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
#app {
  position: relative;
}
.Router {
  // padding: 0 0 71rem 0;
}

@media screen and (min-width: 768px) {
  .el-message {
    min-width: 35.79vw;
  }
  body {
    background: #f5f5f5;
    padding: 8.8rem 0 0 0;

    box-sizing: border-box;
  }
  .navPage {
    margin-top: -8.8rem;
  }
  .wAuto {
    width: 128rem;
    margin: 0 auto;
  }
  // p:hover {
  //   cursor: pointer;
  // }
  // span:hover {
  //   cursor: pointer;
  // }
}
@media screen and (max-width: 768px) {
  body {
    background: #f5f5f5;
    padding: 10.667vw 0 0 0;

    box-sizing: border-box;
  }
  .navPage {
    margin-top: -10.667vw;
  }
  .wAuto {
    width: 100%;
    margin: 0 auto;
    display: none;
  }
  // p:hover {
  //   cursor: pointer;
  // }
  // span:hover {
  //   cursor: pointer;
  // }
}
</style>
