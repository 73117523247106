// import Vue from "vue";
// import VueRouter from "vue-router";

// Vue.use(VueRouter);

export default [
  {
    path: "/recruit",
    component: () => import("@/views/recruit/recruitPage.vue"),
    meta: {
      title: "麦默通 - 招贤纳士",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
  {
    path: "/recruitJob",
    component: () => import("@/views/recruit/recruitJob.vue"),
    meta: {
      title: "麦默通 - 热招职位",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
  {
    path: "/jobDetail",
    component: () => import("@/views/recruit/jobDetail.vue"),
    meta: { title: "招贤纳士", footTitle: "如果您需要了解热招职位的更多信息" },
  },
  {
    path: "/pardon",
    component: () => import("@/views/recruit/pardonPage.vue"),
    meta: {
      title: "麦默通 - 多元包容",
      footTitle: "如果您需要了解热招职位的更多信息",
    },
  },
];
