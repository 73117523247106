import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min.js";
import Vant, { Lazyload } from "vant";
import "vant/lib/index.css";
import "@/assets/text/text.css";
import "@/assets/style/reset.css";
// import VueLazyload from "vue-lazyload";
// import Cookies from "js-cookie";

// const setCookie = (name, value, days) => {
//   let expires = "";
//   if (days) {
//     const date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = "; expires=" + date.toUTCString();
//   }
//   document.cookie = name + "=" + (value || "") + expires + "; path=/";
// };

// // 获取cookie
// const getCookie = (name) => {
//   const nameEQ = name + "=";
//   const ca = document.cookie.split(";");
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == " ") c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// };

// setCookie("myCookieName", "myCookieValue", 30);
// const myCookieValue = getCookie("myCookieName"); // 获取名为"myCookieName"的cookie的值
// console.log(myCookieValue);

// Cookies.set("language", "zh-CN");

// Cookies.set("language", "zh-CN", { expires: 30, path: "/login" });

if (process.env.NODE_ENV === "production") {
  var _hmt = _hmt || [];
  window._hmt = _hmt;
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?d8c34b95d40e2afcdfc2db72c44cf723";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}

Vue.use(Lazyload);
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(Antd);
Vue.use(ElementUI);
import "animate.css";
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
